import { Registry, AutoInjectable, Inject } from '@/commons/ioc/decorators'

import { __NO_TREESHAKE } from '@/commons/utils/magics'

import { SourceModuleLoader } from '@/services/SourceModuleLoader'

import sourcelist from './sourcelist'

__NO_TREESHAKE(Inject)

@AutoInjectable()
@Registry([
  { token: 'sourcelist',            useValue: sourcelist },
  { token: 'SourceModuleLoader',    useToken: SourceModuleLoader },
])
export
class EntryBoot {
  constructor(
    @Inject('SourceModuleLoader') protected readonly loader?,
  ) {
  }

  async bootstrap() {
    const { default: mountApp } = await this.loader.loadHostModule()
    const container = this.getAppContainer()
    const root = container.parentElement!

    root.style.display = 'none'

    await mountApp(container)

    root.style.display = 'block'
  }

  private getAppContainer() {
    let root = document.getElementById('app')

    if (!root) {
      root = document.createElement('div')
      root.id = 'app'

      document.body.append(root)
    }

    let container = root.firstElementChild

    if (!container) {
      container = document.createElement('div')
      root.append(container)
    }

    return container as HTMLElement
  }
}
