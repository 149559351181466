export
default
{
  host: {
    bootstrap: () => import('@/app/index'),
  },

  slots: [
    {
      name: 'error',
      bootstrap: () => import('@/pages/error'),
    },

    /** 菜单管理 */
    {
      name: 'menu',
      bootstrap: () => import('@/pages/menu'),
    },

    /** 角色管理 */
    {
      name: 'role',
      bootstrap: () => import('@/pages/role'),
    },

    /** 人员管理 */
    {
      name: 'personnel',
      bootstrap: () => import('@/pages/personnel'),
    },

    /** 权限管理 */
    {
      name: 'permission',
      bootstrap: () => import('@/pages/permission'),
    },

    /** 首页 */
    {
      name: 'home',
      bootstrap: () => import('@@/revenue/pages/revenue'),

      dependencies: [
        () => import('@@/store/register'),
        () => import('@@/hcard/register'),
        () => import('@@/product/register'),
        () => import('@@/order/register'),
        () => import('@@/settings/register'),
      ]
    },

    /** 门店管理 */
    {
      name: 'store',
      bootstrap: () => import('@@/store/pages/store'),
    },

    /** 店主管理 */
    {
      name: 'storekeeper',
      bootstrap: () => import('@@/store/pages/keeper'),
    },

    /** 商品销售管理 */
    {
      name: 'productsale',
      bootstrap: () => import('@@/product/pages/item'),

      dependencies: [
        () => import('@@/store/register'),
        () => import('@@/settings/register'),
      ]
    },

    /** 氢卡管理 */
    {
      name: 'hcard',
      bootstrap: () => import('@@/hcard/pages/card'),

      dependencies: [
        () => import('@@/store/register'),
      ]
    },

    /** 吸氢签到管理 */
    {
      name: 'hocheckin',
      bootstrap: () => import('@@/hcard/pages/check'),

      dependencies: [
        () => import('@@/store/register'),
      ]
    },

    /** 会员管理 */
    {
      name: 'member',
      bootstrap: () => import('@@/store/pages/member'),
    },

    /** 商品分类管理 */
    {
      name: 'category',
      bootstrap: () => import('@@/product/pages/category'),
    },

    /** 品牌管理 */
    {
      name: 'brand',
      bootstrap: () => import('@@/product/pages/brand'),
    },

    /** 供应商管理 */
    {
      name: 'supplier',
      bootstrap: () => import('@@/product/pages/supplier'),
    },

    /** 商品管理 */
    {
      name: 'product',
      bootstrap: () => import('@@/product/pages/product'),

      dependencies: [
        () => import('@@/store/register'),
      ]
    },

    /** 优惠券管理 */
    {
      name: 'coupon',
      bootstrap: () => import('@@/product/pages/coupon'),

      dependencies: [
        () => import('@@/store/register'),
        () => import('@@/hcard/register'),
      ]
    },

    /** 组合商品管理 */
    {
      name: 'productsuite',
      bootstrap: () => import('@@/product/pages/suite'),

      dependencies: [
        () => import('@@/settings/register'),
      ]
    },

    /** 广告管理 */
    {
      name: 'promotion',
      bootstrap: () => import('@@/promotion/pages/promotion'),
    },

    /** 入口图标管理 */
    {
      name: 'entry',
      bootstrap: () => import('@@/promotion/pages/icon'),
    },

    /** 售出设备管理 */
    {
      name: 'device1',
      bootstrap: () => import('@@/device/pages/device1'),

      dependencies: [
        () => import('@@/store/register'),
      ]
    },

    /** 待售设备管理 */
    {
      name: 'device2',
      bootstrap: () => import('@@/device/pages/device2'),

      dependencies: [
        () => import('@@/store/register'),
        () => import('~/plugins/xlsx/register'),
      ]
    },

    /** 套餐管理 */
    {
      name: 'package',
      bootstrap: () => import('@@/device/pages/package'),

      dependencies: [
        () => import('@@/store/register'),
      ]
    },

    /** 售后管理 */
    {
      name: 'postsale',
      bootstrap: () => import('@@/order/pages/postsale'),

      dependencies: [
        () => import('@@/settings/register'),
        () => import('@@/store/register'),
        () => import('@@/product/register'),
      ]
    },

    /** 订单管理 */
    {
      name: 'order',
      bootstrap: () => import('@@/order/pages/order'),

      dependencies: [
        () => import('@@/settings/register'),
        () => import('@@/store/register'),
        () => import('@@/product/register'),
      ]
    },

    /** 合作管理 */
    {
      name: 'cooperator',
      bootstrap: () => import('@@/revenue/pages/cooperation'),

      dependencies: [
        () => import('@@/store/register'),
      ]
    },

    /** 分销管理 */
    {
      name: 'bindrecord',
      bootstrap: () => import('@@/revenue/pages/binding'),

      dependencies: [
        () => import('@@/store/register'),
      ]
    },

    /** 门店营收 */
    {
      name: 'sales',
      bootstrap: () => import('@@/revenue/pages/sales'),

      dependencies: [
        () => import('@@/store/register'),
      ]
    },

    /** 提现管理 */
    {
      name: 'withdrawal',
      bootstrap: () => import('@@/revenue/pages/withdraw'),

      dependencies: [
        () => import('@@/store/register'),
        // () => import('@@/order/register'),
        () => import('@@/hcard/register'),
      ]
    },

    /** 运费模板管理 */
    {
      name: 'fee',
      bootstrap: () => import('@@/settings/pages/fee'),
    },

    /** 快递公司管理 */
    {
      name: 'express',
      bootstrap: () => import('@@/settings/pages/express'),
    },
  ]
}
