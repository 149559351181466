import { Singleton, Injectable, Inject } from '@/commons/ioc/decorators'

@Singleton()
@Injectable()
export
class SourceModuleLoader {
  registry = {}
  found = {}

  constructor(@Inject('sourcelist') protected readonly sourcelist) {
  }

  loadSlotModule(name) {
    return this.registry[name] ??= this.doLoadModule(name, this.sourcelist.slots)
  }

  loadHostModule() {
    return this.loadSource(this.sourcelist.host)
  }

  protected async doLoadModule(name, slots) {
    for (const source of slots) {
      if (source.name !== name) {
        continue
      }

      try {
        return await this.loadSource(source)
      } catch (error) {
        console.error(error)
        // continue
      }
    }

    return null
  }

  private async loadSource(source) {
    console.log('source', source)

    if (source.dependencies) {
    //   await Promise.all(source.dependencies.map(dependency => dependency()))
      for (const dependency of source.dependencies) {
        await dependency()
      }
    }


    const exports = await source.bootstrap()

    return {
      default: await exports.default,
    }
  }
}
