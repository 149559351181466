export {
  injectable as Injectable,
  inject as Inject,
  // autoInjectable as AutoInjectable,
  singleton as Singleton,
  registry as Registry,
  delay as Delay,
} from 'tsyringe'

import Vue from 'vue'

import { autoInjectable } from 'tsyringe'

export
const AutoInjectable = () => {
  const decorate = autoInjectable()

  return target => {
    const result = decorate(target)

    if (target.prototype && target.prototype instanceof Vue) {
      if (!target.hasOwnProperty('options')) {
        // from `class extends Vue` rather than `Vue.extend`

        const descriptors = Object.getOwnPropertyDescriptors(target.prototype)

        for (const key of Object.keys(descriptors)) {
          if (key === 'constructor') {
            continue
          }

          Object.defineProperty(result.prototype, key, descriptors[key])
        }
      }
    }

    return result
  }
}
